html, body {
    background-color: #000000;
    color: rgba(255, 255, 255, .97);
    font-family: Roboto, sans-serif;
    
    overflow: hidden;
    
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#fps {
    z-index: 99999;

    position: absolute;

    top: -150px;
    right: 30px;

    background-color: rgba(0, 0, 0, .5);

    opacity: 0.8;
   
    margin: 0;
    padding: 10px;

    text-align: right;

    transition: top 1s;

    font-size: 14px;
}

#fps.show {
    top: 20px;
}

#sandbox {
    position: absolute;
    top: 0;
    left: 0;
    
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
}

#overlay {
    position: absolute;
    top: 0;
    left: 0;
    
    z-index: 1;
    
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
}

#perfOverlay {
    z-index: 3;
    
    position: absolute;
    
    top: 70px;
    left: 30px;
    
    opacity: 0.8;
}

#iconCanvas, #thumbnailCanvas {
    display: none;
}

#info {
    z-index: 9;
    
    position: absolute;
    top: 0;
    left: 0;
    
    padding: 28px;
    padding-left: 98px;
    
    width: calc(100% - 28px - 98.2px);
    
    font-size: 16px;
    
    opacity: 0.8;
    
    user-select: none;
}

#info.show > div {
    opacity: 1;
    pointer-events: all;
}

#detailedInfo {
    display: none;
}

#detailedInfo.show {
    overflow: visible;
    display: inline;
}

body > #loadMenu {
    z-index: 101;
    overflow-y: auto;
}

#loadMenu > div {
    cursor: pointer;

    position: relative;
    display: inline-block;

    border-radius: 10px;

    padding: 8px;

    margin: 20px;

    border: rgba(150, 150, 150, 0.2) 3px solid;
    background-color: rgba(21, 24, 27, 0.8);
}

#loadMenu > div > img {
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;

    background-color: rgb(0, 0, 0);
}

#loadMenu > div > div {
    font-size: 24px;
    height: 30px;
}

#pauseMenu, #loadMenu {
    z-index: 100;
    
    position: absolute;
    
    top: 0;
    left: 0;
    
    width: calc(100% - 56px);
    height: calc(100% - 56px);
    
    padding: 28px;
    
    background-color: rgb(0, 0, 0);
    
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s;
    
    text-align: center;

    user-select: none;
}

#pauseMenu.show, #loadMenu.show {
    opacity: 0.9;
    pointer-events: all;
}

#pauseMenu > div {
    width: fit-content;
    
    margin: auto;
    
    padding: 10px;
    
    background-color: rgba(250, 250, 250, 0.15);
}

#pauseMenu > div:first-child {
    background-color: rgba(200, 0, 0, 0.5);
    margin-bottom: 30px;
}

#pauseMenu > .settingPanel, #pauseMenu > .settingButton {
    display: inline-block;
    
    margin: auto;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 30px;
    
    width: 150px;
}

#pauseMenu > .settingButton {
    cursor: pointer;
}

#pauseMenu > #saveOpen {
    background-color: rgba(0, 250, 0, 0.4);
}

#pauseMenu > #loadOpen {
    background-color: rgba(0, 0, 250, 0.4);
}

#pauseMenu > .settingPanelDivide {
    visibility: hidden;
    height: 20px;
    margin: 0;
}

.materialIcon {
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
    
    /*background-color: rgb(150, 150, 150);*/
    /*border: rgb(200, 200, 200) 5px solid;*/
    border: #3e3e3e 5px solid;
    background-color: #1a1b1c;
    
    vertical-align: middle;
    margin-right: 7px;
    
    padding: 3px;
    
    border-radius: 50%;
}

.materialOption {
    display: inline-block;
    margin: 1%;
    
    width: 153px;
    
    border: rgba(150, 150, 150, 0.2) 3px solid;
    background-color: rgba(61, 64, 67, 0.4);
    border-radius: 30px;
    
    cursor: pointer;
}

.materialOption > span {
    overflow: visible;
    
    font-size: 18px;
    text-align: center;
    
    display: inline-block;
    
    width: calc(100% - 42.5px - 7px - 20px);
}

#materialMenu {
    opacity: 0;
    user-select: none;
    pointer-events: none;
    
    transition: opacity .3s;
    
    position: absolute;
    z-index: 5;
    
    top: 110px;
    
    left: 30px;
    
    width: calc(100% - 60px);
    
    max-height: 70%;
    overflow-y: auto;
}

#materialMenu.show {
    opacity: 0.95;
    pointer-events: all;
}

#materialMenuOpener {
    z-index: 10;
    
    position: absolute;
    top: 0;
    left: 0;
    
    cursor: pointer;
    opacity: 0.9;
    
    position: absolute;
    margin: 28px;
    
    width: 40px !important;
    height: 40px !important;
    
    user-select: none;
}

#loadingText {
    position: absolute;
    
    top: 0;
    left: 0;
    
    z-index: 9999;
    
    margin: 0;
    
    background-color: black;
    color: white;
    
    width: 100%;
    height: 100%;
    
    display: none; /*opacity: 0;*/
    
    padding: 2% 0 0 3%;
    
    overflow: hidden;
}

select {
    color: rgba(255, 255, 255, .95);
    background-color: transparent;
}

option {
    background-color: black;
    color: rgba(255, 255, 255, .95);
}

@media only screen and (max-width: 750px) {
    #info > div {
        clear: both;
        margin-right: 0;
    }
    
    #info div:first-child {
        margin-bottom: 10px;
    }
    
    #materialMenu {
        top: 200px;
    }
    
    .materialOption {
        width: 125px;
    }
    
    .materialOption > span {
        font-size: 14px;
    }
}